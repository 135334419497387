:root {
  --primary-color: #ff4e8a;
  --secondary-color: #282c34;
  --text-color: #333333;
  --background-color: #ffffff;
}

.App {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: transparent;
  box-shadow: none;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  padding-top: 8px;
  font-size: 1.5rem;
  color: var(--secondary-color);
}

.logo strong {
  font-weight: 700;
}

.nav-links {
  display: flex;
  gap: 3.5rem;
  margin-right: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.1rem;
  opacity: 0.9;
  padding: 0.5rem 0;
}

.nav-links a:hover {
  opacity: 1;
}

.nav-buttons {
  display: flex;
  gap: 0.8rem;
}

.btn-download {
  background-color: #4e9fff;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.btn-download:hover {
  background-color: #3d8ae6;
}

/* Hero Section */
.hero {
  display: flex;
  padding: 8rem 2rem;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.hero-content {
  flex: 1;
  max-width: 55%;
  padding-right: 0;
  width: 100%;
  box-sizing: border-box;
}

.ratings {
  margin-bottom: 0.5rem;
}

.stars {
  color: #ffd700;
  margin-right: 0.5rem;
}

h1 {
  font-size: 3.2rem;
  margin-bottom: 1.2rem;
  color: var(--secondary-color);
}

.hero-content p {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.app-buttons {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
}

.store-button {
  display: block;
  transition: opacity 0.2s ease;
}

.store-button:hover {
  opacity: 0.85;
}

.store-button img {
  height: 48px;
  width: auto;
  display: block;
}

.hero-image {
  flex: 1;
  display: flex;
  max-width: 28%;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.hero-image img {
  width: 95%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 0;
  box-shadow: none;
  background: transparent;
}

/* Footer Styles */
.footer {
  position: relative;
  padding: 4rem 0 2rem;
  background-color: #f8f9fa;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.footer:before {
  display: none;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 4rem;
  margin-bottom: 3rem;
  padding: 0;
  width: 100%;
}

.footer-section h4 {
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}

.footer-section p {
  color: var(--text-color);
  line-height: 1.6;
  font-size: 0.95rem;
  opacity: 0.8;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.8rem;
}

.footer-section ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 0.95rem;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.footer-section ul li a:hover {
  opacity: 1;
}

.footer-bottom {
  border-top: 1px solid rgba(0,0,0,0.1);
  padding-top: 2rem;
  text-align: center;
}

.footer-bottom p {
  color: var(--text-color);
  font-size: 0.9rem;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    text-align: center;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-section ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-section p {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* Features Section */
.features-wrapper {
  background-color: #f8f9fa;
  width: 100%;
  margin: 0;
  padding: 0;
}

.features {
  position: relative;
  padding: 4rem 0;
  background-color: #f8f9fa;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.features:before {
  display: none;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
}

.features h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 4rem;
  color: var(--secondary-color);
}

.features-content {
  display: flex;
  gap: 6rem;
  align-items: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.features-app-preview {
  flex: 1;
  max-width: 34%;
  flex: 0 0 34%;
}

.feature-screen {
  width: 72%;
  height: auto;
  border-radius: 20px;
  box-shadow: none;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
  background: transparent;
}

.features-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.feature-tab {
  padding: 0.5rem 1.8rem;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
}

.feature-tab:hover {
  transform: translateX(-8px);
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.feature-tab.active {
  background-color: rgba(78, 159, 255, 0.05);
  transform: translateX(-8px);
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.feature-tab h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--secondary-color);
}

.feature-tab p {
  color: var(--text-color);
  line-height: 1.5;
  font-size: 1rem;
}

/* Debug Mode Styles */
.debug-mode .navbar,
.debug-mode .logo,
.debug-mode .nav-links,
.debug-mode .nav-buttons,
.debug-mode .hero,
.debug-mode .hero-content,
.debug-mode .hero-image,
.debug-mode .features,
.debug-mode .features-container,
.debug-mode .features-content,
.debug-mode .features-app-preview,
.debug-mode .features-tabs,
.debug-mode .feature-tab,
.debug-mode .footer,
.debug-mode .footer-container,
.debug-mode .footer-content,
.debug-mode .footer-section,
.debug-mode .footer-bottom {
  outline: 1px solid red;
}

.debug-mode .App {
  outline: 2px solid blue;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
  }

  .hamburger {
    position: static;
    right: auto;
    top: auto;
  }

  .logo {
    padding-top: 0;
  }

  .nav-links {
    flex-direction: column;
    gap: 1rem;
    margin-right: 0;
  }

  .hero {
    flex-direction: column;
    padding: 4rem 1rem;
    width: 100%;
  }

  .hero-content {
    max-width: 100%;
    padding: 0;
    text-align: center;
  }

  .hero-image {
    max-width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  .hero-image img {
    width: 18%;
    margin: 0 auto;
  }

  .features-content {
    flex-direction: column;
    gap: 2rem;
    padding: 0;
  }

  .features-app-preview {
    max-width: 18% !important;
    flex: 0 0 18% !important;
    margin: 0 auto;
  }

  .feature-screen {
    width: 100% !important;
  }

  .features-tabs {
    padding: 0 1rem;
    width: 100%;
  }

  .feature-tab {
    width: 100%;
    margin: 0;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .ratings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .app-buttons {
    justify-content: center;
  }

  .feature-screen {
    width: 45%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.4rem;
  }

  .nav-links a {
    font-size: 1rem;
  }

  .btn-download {
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  .features h2 {
    font-size: 2.2rem;
  }

  .feature-tab h3 {
    font-size: 1.1rem;
  }

  .feature-tab p {
    font-size: 0.9rem;
  }
}

.btn-toggle-debug {
  background-color: #4e9fff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.btn-toggle-debug:hover {
  background-color: #3d8ae6;
}

.logo img {
  height: 30px;
  width: auto;
  display: block;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  text-align: center;
}

.modal p {
  margin-bottom: 1.5rem;
  text-align: center;
  color: #666;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal input {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.modal button[type="submit"] {
  background-color: #4e9fff;
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.modal button[type="submit"]:hover {
  background-color: #3d8ae6;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modal-close:hover {
  color: #333;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.hamburger span {
  width: 2rem;
  height: 0.25rem;
  background: var(--secondary-color);
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.mobile-menu {
  display: none;
}

/* Update existing navbar styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
    position: absolute;
    right: 2rem;
    top: 1.5rem;
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 5rem 2rem 2rem;
    z-index: 5;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .mobile-menu.active {
    display: block;
  }

  .mobile-menu .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 0;
  }

  .mobile-menu .nav-buttons {
    margin-top: 2rem;
    justify-content: center;
  }

  .nav-links, .nav-buttons {
    display: none;
  }

  .mobile-menu .nav-links,
  .mobile-menu .nav-buttons {
    display: flex;
  }

  /* Hamburger animation */
  .hamburger.active span:first-child {
    transform: rotate(45deg);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg);
  }
}

/* Prevent scrolling when menu is open */
body.menu-open {
  overflow: hidden;
}

/* Add/update these CSS rules */

/* Desktop menu */
.desktop-menu {
  display: flex;
}

/* Hamburger styles */
.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .hamburger span {
    width: 2rem;
    height: 0.25rem;
    background: var(--secondary-color);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .hamburger.active span:first-child {
    transform: rotate(45deg);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 5rem 2rem 2rem;
    z-index: 5;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .mobile-menu.active {
    display: block;
  }

  .mobile-menu .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 0;
  }

  .mobile-menu .nav-buttons {
    margin-top: 2rem;
    justify-content: center;
  }

  /* Update navbar positioning */
  .navbar {
    position: relative;
  }
}

/* Prevent scrolling when menu is open */
body.menu-open {
  overflow: hidden;
}

/* Base padding for App container */
.App {
  padding: 0 2%;
}

/* Features container padding */
.features-container {
  padding: 0 2rem;
}

/* Footer container padding */
.footer-container {
  padding: 0 2rem;
}

/* Mobile menu padding */
@media (max-width: 768px) {
  .mobile-menu {
    padding: 5rem 2rem 2rem;
  }
}

@media (max-width: 768px) {
  .App, .features-container, .footer-container {
    padding: 0 1rem;
    width: 100%;
  }

  .features-content,
  .footer-content {
    padding: 0;
    width: 100%;
  }
}

/* Add this to ensure the body doesn't allow horizontal overflow */
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

/* Update mobile styles */
@media (max-width: 768px) {
  /* Update App container */
  .App {
    width: 100%;
    max-width: 100%;
    padding: 0;
    overflow-x: hidden;
  }

  /* Center hero section */
  .hero {
    padding: 4rem 1rem;
    width: 100%;
    max-width: 100%;
  }

  .hero-content {
    max-width: 100%;
    padding: 0 1rem;
  }

  /* Center features section */
  .features {
    width: 100%;
    padding: 4rem 0;
    margin: 0;
    left: 0;
    right: 0;
  }

  .features-container {
    width: 100%;
    padding: 0 1rem;
  }

  .features-content {
    padding: 0 1rem;
  }

  /* Center footer */
  .footer {
    width: 100%;
    padding: 4rem 0 2rem;
    margin: 0;
    left: 0;
    right: 0;
  }

  .footer-container {
    width: 100%;
    padding: 0 1rem;
  }

  /* Update navbar */
  .navbar {
    width: 100%;
    padding: 1rem;
  }
}

/* Update base styles */
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* First, ensure the root elements are properly set */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .feature-tab {
    text-align: center;
    padding: 1rem 1.5rem;
  }

  .feature-tab h3 {
    text-align: center;
    margin-bottom: 0.8rem;
  }

  .feature-tab p {
    text-align: center;
    margin: 0 auto;
  }

  /* Remove the hover/active transform effect on mobile */
  .feature-tab:hover,
  .feature-tab.active {
    transform: none;
  }
}
